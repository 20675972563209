import React from "react";
import linkedinImage from "./images/linkedin.png";
import instagramImage from "./images/instagram.png";
import youtubeImage from "./images/youtube (4).png";

function FollowUs() {
  return (
    <div className="follow-us">
      <div className="follow-text">Suivez-nous :</div>
      <div className="social-icons">
        <a
          href="https://www.instagram.com/ghm_labs.ma/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagramImage} alt="Instagram" />
        </a>
        <a
          href="https://www.youtube.com/@GHM_LABS"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={youtubeImage} alt="YouTube" />
        </a>
        <a
          href="https://www.linkedin.com/company/ghm-labs/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedinImage} alt="LinkedIn" />
        </a>
      </div>
    </div>
  );
}

export default FollowUs;
