import React from "react";

function CookieModal({ showModal, setShowModal }) {
  if (!showModal) return null;

  return (
    <div id="cookieModal" className="modal">
      <div className="modal-content">
        <span className="close" onClick={() => setShowModal(false)}>
          &times;
        </span>
        <h2 style={{ color: "black" }}>
          Utilisation des cookies et protection des données
        </h2>
        <p>
          Nous utilisons des cookies pour vous offrir la meilleure expérience
          possible sur notre site. En continuant à naviguer sur ce site, vous
          acceptez l'utilisation de cookies. Pour en savoir plus sur notre
          politique de confidentialité et la gestion de vos données
          personnelles, veuillez consulter notre{" "}
          <a href="politique.html" target="_blank" rel="noopener noreferrer">
            Politique de Confidentialité
          </a>
          .
        </p>
        <div className="modal-buttons">
          <button onClick={() => setShowModal(false)}>Accepter</button>
          <button onClick={() => setShowModal(false)}>Refuser</button>
        </div>
      </div>
    </div>
  );
}

export default CookieModal;
