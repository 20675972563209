import React, { useState } from 'react';

function HomeButton() {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <button id="home-button" className="home-button" onClick={toggleMenu}>
        <i className="fas fa-bars my-icon"></i>
      </button>
      <div
        id="vertical-menu"
        className={`vertical-menu ${menuVisible ? 'active' : ''}`}
      >
        <a href="https://ghm-labs.ma/blog-3/" className="menu-item">Blog</a>
        <a href="about.html" className="menu-item">À propos</a>
      </div>
    </>
  );
}

export default HomeButton;

