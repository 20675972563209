// src/pages/Accueil.js
import React, { useState, useEffect } from "react";

import Navbar from "../components/Navbar";
import MainContent from "../components/MainContent";
import "./Accueil.css";
import CookieModal from "../CookieModal";
function Accueil() {
  
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const modal = document.getElementById("cookieModal");
    const closeBtn = document.querySelector(".close");
    const acceptBtn = document.getElementById("acceptCookies");
    const refuseBtn = document.getElementById("refuseCookies");

    if (modal) {
      modal.style.display = "block";
    }

    if (closeBtn) {
      closeBtn.onclick = () => {
        modal.style.display = "none";
      };
    }

    if (acceptBtn) {
      acceptBtn.onclick = () => {
        modal.style.display = "none";
      };
    }

    if (refuseBtn) {
      refuseBtn.onclick = () => {
        modal.style.display = "none";
      };
    }

    window.onclick = (event) => {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };
  }, []);

  return (
    <div className="accueil-page">
      <Navbar />
      <MainContent />
      <CookieModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default Accueil;
