// App.js

import React, { useState, useEffect } from "react";
import "./PageForm.css"; // Import your styles
import FormContainer from "../FormContainer"; // Import any other components
import FollowUs from "../FollowUs";

import HomeButton from "../HomeButton";

import "../FormContainer.css";

function PageForm() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const modal = document.getElementById("cookieModal");
    const closeBtn = document.querySelector(".close");
    const acceptBtn = document.getElementById("acceptCookies");
    const refuseBtn = document.getElementById("refuseCookies");

    if (modal) {
      modal.style.display = "block";
    }

    if (closeBtn) {
      closeBtn.onclick = () => {
        modal.style.display = "none";
      };
    }

    if (acceptBtn) {
      acceptBtn.onclick = () => {
        modal.style.display = "none";
      };
    }

    if (refuseBtn) {
      refuseBtn.onclick = () => {
        modal.style.display = "none";
      };
    }

    window.onclick = (event) => {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };
  }, []);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setShowConfirmation(true);
    setTimeout(() => {
      setShowConfirmation(false);
    }, 20000);
  };

  return (
    <div className="PageForm">
      <HomeButton />
      <div className="content">
        <h2>Les meilleurs agences d'ergonomie web (UX/UI)</h2>
        <h3>A qui faire confiance pour votre entreprise ?</h3>
        {showConfirmation && (
          <div className="confirmation-message">
            Félicitations! Vous êtes bien enregistré. Vous avez une réduction de
            40% si vous validez l’agence que nous proposons.
            <br />
            Nous prendrons contact avec vous dans 24H afin de vous aider à
            trouver les meilleures offres.
            <br />
            Satisfait ou remboursé.
          </div>
        )}
        <FormContainer onSubmit={handleFormSubmit} />
        <FollowUs />
      </div>
      
    </div>
  );
}

export default PageForm;
