import React from "react";
import { useNavigate } from "react-router-dom";
import "./MainContent.css";

const MainContent = () => {
  const navigate = useNavigate();

  const handleStartNow = () => {
    navigate("/PageForm");
  };

  return (
    <div className="main-content">
      <h1 className="main-heading">
        Trouvez le&nbsp;
        <br />
        prestataire de&nbsp;
        <br />
        services idéales.
      </h1>

      <p className="sub-heading">
        Nous aidons votre entreprise à &nbsp;
        <br /> s'entourer des meilleurs partenaires.
      </p>
      <button className="btn-start" onClick={handleStartNow}>
        Commencez maintenant
      </button>
    </div>
  );
};

export default MainContent;
