import React, { useRef, useState } from 'react';

function FormContainer() {
  const formRef = useRef(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(formRef.current);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://agence-digital-marketing/api/ajouter', true);

    xhr.onload = function () {
      if (xhr.status === 200) {
        // Affiche un message de succès
        setSuccessMessage('Félicitations! Vous êtes bien enregistré. Vous avez une réduction de 40% si vous validez l’agence que nous proposons.Nous prendrons contact avec vous dans 24H afin de vous aider à trouver les meilleures offres.Satisfait ou remboursé.');

        // Masque le formulaire
        formRef.current.style.display = 'none';

        // Réinitialise le formulaire
        formRef.current.reset();

        // Réaffiche le formulaire et cache le message de succès après 20 secondes
        setTimeout(function () {
          formRef.current.style.display = 'block';
          setSuccessMessage(null);
        }, 20000); // 20000 millisecondes = 20 secondes
      } else {
        setError('Erreur de soumission du formulaire: ' + xhr.statusText);
      }
    };

    xhr.onerror = function () {
      setError('Erreur de réseau ou de serveur.');
    };

    xhr.send(formData);
  };

  return (
    <div className="form-container">
      {successMessage && (
        <div style={{ 
          color: 'green', 
          backgroundColor: '#d4edda', 
          border: '1px solid green', 
          padding: '10px', 
          marginBottom: '20px', // Modifie la marge pour espacer le message du formulaire
          borderRadius: '5px'
        }}>
          {successMessage}
        </div>
      )}
      
      <form onSubmit={handleSubmit} ref={formRef} id="myForm">
        <label htmlFor="Raison_social">Raison sociale :</label>
        <input type="text" id="Raison_social" name="Raison_social" required />

        <label htmlFor="Adresse_Mail">Adresse Mail Pro :</label>
        <input type="email" id="Adresse_Mail" name="Adresse_Mail" required />

        <label htmlFor="Téléphone">Téléphone :</label>
        <input type="text" id="Téléphone" name="Téléphone" />

        <label htmlFor="message">Message:</label>
        <textarea id="message" name="message" required></textarea>

        <input type="submit" value="Trouver mon agence" />
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
}

export default FormContainer;
